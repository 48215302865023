@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,100&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.score-color {
  width: 100%;
}
.score-color-flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #fff;
}
.score-color-flex.active {
  background: #f7e9ea;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #f9f9f9;
}
.score-color-flex-team h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #141414;
}
.score-color-flex-score {
  display: flex;
  align-items: center;
}
.score-color-flex-score p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}
.score-color-flex-score h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #141414;
}
.score-color-flex-score span {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  color: #747474;
  padding-right: 6px;
}
.score-color-flex-score i {
  font-size: 16px;
  padding: 0px 10px;
}
table.table thead tr th {
  color: #141414;
  font-weight: 500;
  font-size: 12px;
}
table.table tbody tr {
  position: relative;
}
.score-card-table tbody tr:nth-child(even) {
  background: #f2f3f5;
}
.score-card-table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  color: #141414;
  position: relative;
}
.score-card-table tbody tr td p {
  margin-bottom: -6px;
  font-weight: 400;
  font-size: 14px;
  color: #141414;
}
.score-card-table tbody tr td p.active {
  font-weight: 700;
  font-size: 14px;
  color: #141414;
}
/* table.table tbody tr td p.active::after {
  content: "*";
} */
.score-card-table tbody tr td h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}
table.score-card-table tbody tr td span {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 10px;
  color: #d25116;
}
.table.score-card-table tbody tr td span.active {
  color: #c61d24;
  font-weight: 500;
}
table.score-card-table tbody tr td p b {
  padding-left: 4px;
}
.extra-run {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dee2e6;
  padding: 10px 8px;
  background: #f9e8e9;
}
.extra-run-total {
  display: flex;
  align-items: center;
  justify-content: center;
}
.extra-run-total span {
  font-weight: 400;
  font-size: 12px;
  color: #141414;
}
.extra-run-show p {
  font-size: 14px;
  font-weight: 700;
  color: #0c0c0c;
  margin-bottom: -6px;
}
.extra-run-show span {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #747474;
}
.extra-run-show {
  max-width: 186px;
  width: 100%;
}
.extra-run-total p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  padding-right: 10px;
}

.total-wicket {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dee2e6;
  padding: 10px 8px;
  background: #c61d24;
}
.total-wicket-show {
  max-width: 186px;
  width: 100%;
}
.total-wicket-show span {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 500;
  color: #757575;
}
.total-wicket-show p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: -6px;
  color: #fff;
}
.total-wicket-total span {
  font-weight: 600;
  font-size: 14px;
  padding-left: 10px;
  color: #ffffff;
}
.total-wicket-total {
  display: flex;
  align-items: center;
}
.total-wicket-total p {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
}
.not-out {
  border: 1px solid #dee2e6;
  padding: 10px 8px;
}

.not-out p {
  font-size: 14px;
  font-weight: 400;
  color: #141414;
  margin-bottom: -4px;
}
.not-out span {
  font-size: 14px;
  font-weight: 400;
  color: #747474;
}
.App {
  overflow: hidden;
}

.wicket-show p {
  background-color: #f0f0f0;
  max-width: 30px;
  border-radius: 16px;
  width: 100%;
  text-align: center;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  padding: 3px 0px;
}

.wicket-show p.red {
  background-color: #c61d24;
  color: #fff;
}
.wicket-show p.green {
  background-color: #00ae00;
  color: #fff;
}
.wicket-show p.yello {
  background-color: #ffb900;
  color: #000000;
}
.wicket-show span {
  font-size: 14px;
  font-weight: 700;
  max-width: 56px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.game-status {
  border-top: 10px solid #f5f5f5;
}
.game-status-p {
  font-size: 14px;
  font-weight: 600;
  color: #997c1a;
  margin-bottom: 0px;
  text-align: center;
}

.commentry-style {
  background-color: #fff;
  border-bottom: 10px solid #f5f5f5;
}
.commentry-style-space {
  padding: 15px 8px;
}
.commentry-screen-p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.commentry-name {
  background-color: #f0f0f0;
  position: relative;
  padding: 7px 16px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commentry-points {
  font-size: 16px;
  font-weight: 700;
  color: #4d4d4d;
}
.commentry-points.high {
  color: #279c4b;
}
.commentry-image img {
  height: 100%;
  max-height: 29px;
}
.commentry-image span {
  font-size: 14px;
  color: #525252;
  padding: 0px 20px;
  font-weight: 500;
}
.commentry-point {
  display: flex;
  align-items: center;
  border: 2px solid #2e8844;
  max-width: 100px;
  /* justify-content: center; */
  border-radius: 17px;
  margin-top: 15px;
}
.commentry-point-t {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  max-width: 50px;
  width: 100%;
  color: #21903f;
  text-align: center;
  border-right: 1px solid #fff;
}
.commentry-point-point {
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  color: #195b29;
  padding: 4px 0px;
  max-width: 48px;
  width: 100%;
  background-color: #eef9ee;
  margin-bottom: 0px;
  border: 1px solid #eef9ee;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}
.commentry-points i {
  margin-right: 10px;
}
.wicket_style img {
  width: 100%;
}
.end_over {
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-top: 1px solid #9e8c8c;
  border-bottom: 1px solid #9e8c8c;
}
.end_over h6 {
  font-size: 16px;
  font-weight: 700;
}
.end_over_flex {
  display: flex;
  padding-right: 12px;
  align-items: center;
}
.end_over_flex p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  color: #717171;
  margin-top: 2px;
}
.end_over_flex span {
  color: #717171;
}
.navbar a {
  font-size: 16px;
  font-weight: 800;
  text-decoration: none;
  color: #000;
}
.score-card-table thead tr {
  background: #f7e9ea;
  /* opacity: 0.1; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
}
.live-score-card-ball {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px;
  background: #f3c9cc;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
  border-left: 6px solid #c61d24;
}
.wicket-show {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.live-score-card-ball p {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 14px;
}
.live-score-card-balls span {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
  text-align: center;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 700;
  padding: 4px 8px;
  margin: 0px 4px;
}
.live-score-card-balls span.six {
  background-color: #00ae00;
  border: 1px solid #00ae00;
  color: #fff;
}
.live-score-card-balls span.wicket {
  background-color: #c61d24;
  border: 1px solid #c61d24;
  color: #fff;
}
.live-score-card-balls span.four {
  border: 1px solid #ffb900;
  background-color: #ffb900;
  color: #000000;
}
.live-score-card-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 15px;
  background: #f7e9ea;
}
/* .live-score-card {
  padding: 0px 20px;
} */
.live-score-card-score p {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: -8px;
}
.live-score-card-score span {
  font-weight: 700;
  font-size: 14px;
}
.live-score-card-bet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.live-score-card-bets {
  display: flex;
  flex-direction: column;
}
.live-score-card-betname p {
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #232323;
}
.live-score-card-bets p {
  font-weight: 700;
  font-size: 14px;
  color: #232323;
  margin-bottom: 6px;
}
.commentry-screen-span {
  font-size: 14px;
  font-weight: 400;
}
.score-show-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.score-show-flex-team span {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}
.score-show-flex-live p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.score-show-flex-live p::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #f24237;
  margin-right: 5px;
}
.score-show-flex-team p {
  font-size: 14px;
  font-weight: 700;
}

.player-state tbody tr td:first-child {
  padding: 13px 0px 0px 0px;
}
.player-state-div {
  display: flex;
  align-items: center;
  position: relative;
}
.player-state-div div {
  margin-left: 14px;
}
.player-state-div div p {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  margin-bottom: -5px;
}
.player-state-div div span {
  font-weight: 400;
  font-size: 11px;
  color: #8e9193;
}
.player-state tbody tr td {
  font-weight: 500;
  font-size: 14px;
  color: #3a3a3a;
  vertical-align: middle;
}
.player-state tbody tr td:last-child {
  font-weight: 400;
  font-size: 14px;
  color: #989898;
}
.player-state thead tr th:first-child,
.player-state tbody tr td:first-child {
  border-right: 1.5px solid #d5d5d7;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #d5d5d7;
}
.player-state-div svg.player-status {
  position: absolute;
  top: 0;
  left: 30px;
}
.player-state tbody tr.active {
  background: #ffe8db;
}
.player-points-view {
  background: #000;
  padding: 10px 0px 0px 10px;
  display: flex;
  align-items: end;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.player-points-view-details h6 {
  font-weight: 500;
  font-size: 11px;
  margin-bottom: -6px;
  color: #ffffff;
}
.player-points-view-details span {
  font-weight: 400;
  font-size: 8.5px;
  color: #8e9193;
}
.player-points-view-p {
  margin-right: 20px;
}
.player-points-view-status {
  font-weight: 400;
  font-size: 9.5px;
  margin-bottom: 4px;
  color: #8e9193;
}
.player-points-view-p p {
  font-weight: 500;
  font-size: 11px;
  margin-bottom: 0px;
  color: #8e9193;
}
.player-points-view-p h5 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
  color: #8e9193;
}
.player-points-view-flex {
  display: flex;
  align-items: center;
}
.player-points-view-details {
  margin-left: 18px;
}
.player-points-view-table tbody tr td {
  font-weight: 400;
  font-size: 10px;
  color: #000000;
}
.player-points-view-table thead tr th:first-child {
  padding-right: 100px;
}
.player-points-view-head {
  display: flex;
  align-items: center;
}
.player-points-view-table thead tr th {
  font-weight: 400;
  font-size: 9.5px;
  color: #8e9193 !important;
  padding: 6px 8px;
}
.player-points-view-table thead tr {
  background: #f7f7f7;
}
.player-points-view-table thead {
  overflow: auto;
}
.player-points-view-table thead tr th {
  position: sticky;
  top: 0;
}
.player-points-view-table {
  margin-top: 95px;
  z-index: 0;
}
.loading-container {
  width: 100%;
  text-align: center;
  margin: 2rem auto;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f3c9cc;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
